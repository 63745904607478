import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const styles = {
	root: {
		width: '104px',
		height: '46px',
		padding: '5px',
		backgroundColor: '#FCFCFC',
		boxShadow: '0 3px 6px #00000029',
		borderRadius: '30px',
		cursor: 'pointer',
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#F0F3F5',
		borderRadius: '30px',
	},
	item: {
		width: '46px',
		height: '46px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: 'mw-bold',
		color: 'text.primary',
		'-webkit-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		userSelect: 'none',
		borderRadius: '50%',
	},
	selectedItem: {
		color: '#FFF',
		backgroundColor: 'primary.main',
	},
};

const LanguageSelector = ({ language, onChange, style }) => {
	const getSx = (lang) => (lang === language ? { ...styles.item, ...styles.selectedItem } : styles.item);
	const handleClick = () => {
		onChange(language === 'hu' ? 'ro' : 'hu');
	};

	return (
		<Box sx={{ ...styles.root, ...style }} onClick={handleClick}>
			<Box sx={styles.content}>
				<Box sx={getSx('hu')}>Hu</Box>
				<Box sx={getSx('ro')}>Ro</Box>
			</Box>
		</Box>
	);
};

/* eslint-disable react/forbid-prop-types */
LanguageSelector.propTypes = {
	language: PropTypes.oneOf(['hu', 'ro']).isRequired,
	onChange: PropTypes.any.isRequired,
	style: PropTypes.any,
};

LanguageSelector.defaultProps = {
	style: {},
};

export default LanguageSelector;
