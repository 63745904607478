import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const BP1 = '@media (max-width: 1079px)';
const BP2 = '@media (max-width: 359px)';

const styles = {
	root: {
		position: 'relative',
		width: '100%',
		minHeight: '970px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#000',
		boxSizing: 'border-box',
		transition: 'all .3s',
		[BP1]: {
			minHeight: '700px',
		},
	},
	image: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		opacity: 0.5,
	},
	triangle: {
		position: 'absolute',
		backgroundColor: 'background.primary',
		width: '100%',
		height: '100%',
		top: 0,
		clipPath: 'polygon(93% 0, 100% 0, 100% 100%, 70% 100%)',
	},
	content: {
		zIndex: 10,
		position: 'absolute',
		width: '100%',
		maxWidth: '1010px',
		pl: '20px',
		pr: '20px',
		boxSizing: 'border-box',
	},
	title: {
		fontFamily: 'mw-bold',
		fontSize: '70px',
		lineHeight: '90px',
		letterSpacing: '-1px',
		color: '#FFF',
		wordBreak: 'break-word',
		overflowWrap: 'anywhere',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		transition: 'all .3s',
		[BP1]: {
			fontSize: '40px',
			lineHeight: '51px',
		},
		[BP2]: {
			fontSize: '28px',
			lineHeight: '36px',
		},
	},
};

const HeaderBanner = ({ title, image, onClick }) => {
	const { t } = useTranslation(['home']);
	return (
		<Box sx={styles.root}>
			<Box sx={styles.content}>
				<Typography sx={styles.title}>{title}</Typography>
				<Button color='btn' variant='contained' sx={{ mt: '40px' }} onClick={onClick}>{t('nextButton')}</Button>
			</Box>
			<Box sx={{ ...styles.image, backgroundImage: `url(${image})` }} />
			<Box sx={styles.triangle} />
		</Box>
	);
};

/* eslint-disable react/forbid-prop-types */
HeaderBanner.propTypes = {
	title: PropTypes.string.isRequired,
	image: PropTypes.any.isRequired,
	onClick: PropTypes.any.isRequired,
};

export default HeaderBanner;
