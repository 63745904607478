import React, { useEffect } from 'react';
import { createTheme, ThemeProvider, Box } from '@mui/material';
import {
	BrowserRouter as Router,
	Switch,
	Route, Redirect,
} from 'react-router-dom';
import routes from './navigation/routes';
import Footer from './components/Footer';
import i18n from './lib/i18n/i18n';

const COLORS = {
	blue1: '#00365E',
	blue2: '#002846',
	blue3: '#0F2733',
	blue4: '#05131A',
	lightText: '#F0F3F5',
};

const theme = createTheme({
	typography: {
		fontFamily: 'mw-bold',
	},
	palette: {
		primary: {
			main: COLORS.blue1,
		},
		text: {
			primary: COLORS.blue1,
			dark: COLORS.blue3,
			darker: COLORS.blue4,
			light: '#FFF',
		},
		background: {
			primary: COLORS.blue1,
			dark: COLORS.blue4,
		},
		btn: {
			main: '#FCFCFC',
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					body1: 'div',
					bannerTitle: 'div',
					bannerSubtitle: 'div',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				contained: {
					width: '200px',
					height: '56px',
					backgroundColor: '#FCFCFC',
					color: COLORS.blue2,
					boxShadow: '0px 3px 6px #00000029',
					borderRadius: '30px',
					fontFamily: 'mw-bold',
					fontSize: '20px',
					textTransform: 'none',
					marginTop: '82px',
				},
			},
		},
	},
});

const styles = {
	root: {
		position: 'relative',
		boxSizing: 'border-box',
		width: '100%',
	},
	content: {
		width: '100%',
		transition: 'all .3s',
	},
};

const App = () => {
	useEffect(() => {
		(async () => {
			const lang = localStorage.getItem('lang');
			if (lang === 'hu' || lang === 'ro') {
				await i18n.changeLanguage(lang);
			}
		})();
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Box sx={styles.root}>
				<Router>
					<Box sx={styles.content}>
						<Switch>
							{routes.map(({ path, component, exact }, i) => (
								<Route
									key={i.toString()}
									path={path}
									component={component}
									exact={exact}
								/>
							))}
							<Route path='*'>
								<Redirect to='/' />
							</Route>
						</Switch>
					</Box>
				</Router>
				<Footer />
			</Box>
		</ThemeProvider>
	);
};

export default App;
