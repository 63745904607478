import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const styles = {
	root: {
		width: '100%',
		boxSizing: 'border-box',
		backgroundColor: '#F0F3F5',
		pt: '77px',
		pb: '82px',
		pl: '20px',
		pr: '20px',
	},
	content: {
		width: '100%',
		maxWidth: '1128px',
		ml: 'auto',
		mr: 'auto',
	},
	title: {
		fontFamily: 'mw-bold',
		fontSize: '50px',
		lineHeight: '60px',
		letterSpacing: '-1px',
		color: 'text.primary',
		wordBreak: 'break-word',
		overflowWrap: 'anywhere',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		// maxWidth: '264px'
	},
	textCard: {
		width: '100%',
		backgroundColor: '#FFF',
		boxShadow: '0px 50px 80px #0D988C1A',
		borderRadius: '12px',
		boxSizing: 'border-box',
		pt: '40px',
		pb: '50px',
		pl: '48px',
		pr: '48px',
		fontFamily: 'mw',
		fontSize: '24px',
		lineHeight: '40px',
		color: 'text.dark',
		mt: '43px',
		wordBreak: 'break-word',
		overflowWrap: 'anywhere',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
	},
	dot: {
		width: '12px',
		height: '12px',
		backgroundColor: '#002846',
		borderRadius: '50%',
		ml: 'auto',
		mr: 'auto',
	},
};

// eslint-disable-next-line react/display-name
const TextContainer = forwardRef(({ title, text }, ref) => (
	<Box sx={styles.root}>
		<Box sx={styles.content}>
			<Typography ref={ref} sx={{ ...styles.title, marginBottom: '10px' }}>__</Typography>
			<Typography sx={styles.title}>{title}</Typography>
			<Box sx={styles.textCard}>
				<Box sx={{ ...styles.dot, mb: '20px' }} />
				{text}
				<Box sx={{ ...styles.dot, mt: '30px' }} />
			</Box>
		</Box>
	</Box>
));

/* eslint-disable react/forbid-prop-types */
TextContainer.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default TextContainer;
