import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import banner1 from '../../assets/images/banner/banner1.jpg';
import banner2 from '../../assets/images/banner/banner2.jpg';
import banner3 from '../../assets/images/banner/banner3.jpg';
import TextContainer from './components/TextContainer';
import Guarantee from '../../components/Guarantee';
import HeaderBanner from './components/HeaderBanner';
import Banner from './components/Banner';
import Header from '../../components/Header';

const BP1 = '@media (max-width: 1069px)';
const BP2 = '@media (max-width: 779px)';

const styles = {
	root: {
		boxSizing: 'border-box',
		overflow: 'hidden',
		transition: 'all .3s',
		[BP1]: {
			pt: '155px',
		},
		[BP2]: {
			pt: '125px',
		},
	},
};

const Home = () => {
	const [headerShrunk, setHeaderShrunk] = useState(false);
	const { t } = useTranslation(['home']);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);

	const refs = [ref1, ref2, ref3];

	const scroll = (index) => {
		refs[index].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	return (
		<>
			<Header shrunk={headerShrunk} onChange={setHeaderShrunk} />
			<Box sx={styles.root}>
				<HeaderBanner title={t('banner1.title')} image={banner1} onClick={() => scroll(0)} />
				<TextContainer ref={ref1} title={t('text1.title')} text={t('text1.text')} />
				<Guarantee />
				<Banner title={t('banner2.title')} color='#b1beb6' image={banner2} onClick={() => scroll(1)} />
				<TextContainer ref={ref2} title={t('text2.title')} text={t('text2.text')} />
				<Banner title={t('banner3.title')} color='#b38d8d' image={banner3} rightImage onClick={() => scroll(2)} />
				<TextContainer ref={ref3} title={t('text3.title')} text={t('text3.text')} />
				<Guarantee />
			</Box>
		</>
	);
};

export default Home;
