import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../assets/images/logo.svg';
import facebookIcon from '../assets/images/social/facebook.svg';
import twitterIcon from '../assets/images/social/twitter.svg';
import mailIcon from '../assets/images/social/mail.svg';

const styles = {
	root: {
		width: '100%',
		backgroundColor: 'background.dark',
		paddingTop: '86px',
		paddingBottom: '63px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingLeft: '20px',
		paddingRight: '20px',
		boxSizing: 'border-box',
	},
	text: {
		fontFamily: 'roboto',
		fontSize: '20px',
		lineHeight: '28px',
		color: '#FFF',
		textAlign: 'center',
	},
	socialContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: '24px 24px',
		marginTop: '40px',
		flexWrap: 'wrap',
	},
	socialIcon: {
		width: '30px',
		height: '30px',
		transition: 'all .1s',
		cursor: 'pointer',
		'&:hover': {
			transform: 'scale(1.2)',
		},
	},
	logoContainer: {
		width: '100%',
		maxWidth: '200px',
		marginBottom: '26px',
	},
};

const Footer = () => {
	const renderSocialIcon = (icon, href) => (
		<a href={href} rel='noopener noreferrer'>
			<Box sx={styles.socialIcon}>
				<img src={icon} style={{ width: '100%' }} alt='Social icon' />
			</Box>
		</a>
	);

	return (
		<Box sx={styles.root}>
			<Box sx={styles.logoContainer}><img src={logo} style={{ width: '100%' }} alt='Logo' /></Box>
			<Typography sx={{ ...styles.text, fontWeight: 'bold' }}>Mountain Rest SRL</Typography>
			<Typography sx={styles.text}>537298 Ciceu nr. 636/A</Typography>
			<Box sx={styles.socialContainer}>
				{renderSocialIcon(facebookIcon, 'https://google.com')}
				{renderSocialIcon(twitterIcon, 'https://google.com')}
				{renderSocialIcon(mailIcon, 'https://google.com')}
			</Box>
		</Box>
	);
};

export default Footer;
