import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const styles = {
	dotsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '0 16px',
		height: '40px',
	},
	dot: {
		width: '10px',
		height: '10px',
		borderRadius: '50%',
	},
};

const SliderDots = ({ currentSlide, totalCount, style }) => (
	<Box sx={{ ...styles.dotsContainer, ...style }}>
		{Array.from(new Array(totalCount).keys()).map((item) => (
			<Box
				key={item}
				sx={{
					...styles.dot,
					opacity: item === currentSlide ? 1 : 0.3,
					backgroundColor: item === currentSlide ? '#FFF' : '#002846',
				}}
			/>
		))}
	</Box>
);

/* eslint-disable react/forbid-prop-types */
SliderDots.propTypes = {
	currentSlide: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	style: PropTypes.any,
};

SliderDots.defaultProps = {
	style: {},
};

export default SliderDots;
