import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import badge from '../assets/images/badge.svg';

const BP1 = '@media (max-width: 1039px)';
const BP2 = '@media (max-width: 479px)';
const BP3 = '@media (max-width: 359px)';

const styles = {
	root: {
		position: 'relative',
		width: '100%',
		height: '300px',
		boxSizing: 'border-box',
		backgroundColor: '#FFF',
		transition: 'all .3s',
		[BP1]: {
			height: 'unset',
		},
	},
	kecske: {
		position: 'absolute',
		backgroundColor: 'background.primary',
		width: '100%',
		height: '100%',
		top: 0,
		clipPath: 'polygon(calc(50% + 60px) 0, 100% 0, 100% 100%, calc(50% - 60px) 100%)',
		[BP1]: {
			display: 'none',
		},
	},
	content: {
		zIndex: 20,
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		display: 'flex',
		flexDirection: 'row',
		boxSizing: 'border-box',
		alignItems: 'center',
		gap: '0 60px',
		transition: 'all .3s',
		[BP1]: {
			position: 'relative',
			flexDirection: 'column',
		},
	},
	left: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row-reverse',
		gap: '0 24px',
		alignItems: 'center',
		pl: '20px',
		pr: '20px',
		transition: 'all .3s',
		paddingTop: '24px',
		paddingBottom: '24px',
		[BP1]: {
			pb: '24px',
		},
		[BP3]: {
			flexDirection: 'column-reverse',
		},
	},
	right: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		ml: '130px',
		transition: 'all .3s',
		[BP1]: {
			ml: '0px',
			pt: '24px',
			pb: '24px',
			backgroundColor: 'background.primary',
			width: '100%',
			alignItems: 'center',
		},
	},
	listContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
		pr: '20px',
		[BP1]: {
			pl: '20px',
		},
	},
	title: {
		maxWidth: '280px',
		fontFamily: 'mw-bold',
		fontSize: '30px',
		color: 'text.primary',
		transition: 'all .3s',
		overflowWrap: 'anywhere',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		[BP2]: {
			fontSize: '24px',
		},
		[BP3]: {
			flexDirection: 'column-reverse',
			textAlign: 'center',
		},
	},
	listItem: {
		display: 'flex',
		flexDirection: 'row',
		gap: '0 10px',
		alignItems: 'center',
	},
	listItemText: {
		fontFamily: 'mw-bold',
		fontSize: '20px',
		color: '#F0F3F5',
		transition: 'all .3s',
		overflowWrap: 'anywhere',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		[BP2]: {
			fontSize: '16px',
		},
	},
	circle: {
		minWidth: '35px',
		minHeight: '35px',
		borderRadius: '50%',
		backgroundColor: '#FFF',
		fontFamily: 'mw-bold',
		fontSize: '20px',
		color: 'text.primary',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transition: 'all .3s',
		[BP2]: {
			minWidth: '25px',
			minHeight: '25px',
			fontSize: '16px',
		},
	},
};

const Guarantee = () => {
	const { t } = useTranslation();

	const renderItem = (index, text) => (
		<Box sx={styles.listItem}>
			<Box sx={styles.circle}>{index}</Box>
			<Typography sx={styles.listItemText}>{text}</Typography>
		</Box>
	);

	return (
		<Box sx={styles.root}>
			<Box sx={styles.content}>
				<Box sx={styles.left}>
					<Box>
						<Typography sx={styles.title}>A Madéfalvi Hús</Typography>
						<Typography sx={styles.title}>{t('guarantee.title')}</Typography>
					</Box>
					<img src={badge} style={{ width: 72 }} alt='Badge' />
				</Box>
				<Box sx={styles.right}>
					<Box sx={styles.listContainer}>
						{[1, 2, 3, 4].map((item) => (
							<React.Fragment key={item}>
								{renderItem(item, t(`guarantee.s${item}`))}
							</React.Fragment>
						))}
					</Box>
				</Box>
			</Box>
			<Box sx={styles.kecske} />
		</Box>
	);
};

export default Guarantee;
