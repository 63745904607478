import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BP1 = '@media (max-width: 1229px)';
const BP2 = '@media (max-width: 939px)';

const styles = {
	root: {
		position: 'relative',
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transition: 'all .3s',
	},
	triangle: {
		position: 'absolute',
		backgroundColor: 'background.primary',
		width: '100%',
		height: '100%',
		top: 0,
		clipPath: 'polygon(0 0, 6% 0, 29% 100%, 0 100%)',
		[BP2]: {
			display: 'none',
		},
	},
	triangleR: {
		clipPath: 'polygon(94% 0, 100% 0, 100% 100%, 71% 100%)',
	},
	content: {
		position: 'relative',
		zIndex: 10,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '60px 60px',
		mt: '148px',
		mb: '148px',
		ml: '20px',
		mr: '20px',
		[BP2]: {
			flexDirection: 'column',
		},
	},
	contentR: {
		flexDirection: 'row-reverse',
	},
	imageContainer: {
		boxSizing: 'border-box',
		maxWidth: '552px',
		boxShadow: '0px 50px 80px #00365E33',
		borderRadius: '12px',
		overflow: 'hidden',
		lineHeight: '0px',
	},
	textContainer: {
		width: '100%',
		maxWidth: '552px',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		fontFamily: 'mw-bold',
		fontSize: '50px',
		lineHeight: '60px',
		color: 'text.primary',
		[BP1]: {
			fontSize: '40px',
			lineHeight: '48px',
		},
	},
};

const Banner = ({
	title, color, image, rightImage, onClick,
}) => {
	const { t } = useTranslation(['home']);
	return (
		<Box sx={{ ...styles.root, backgroundColor: color }}>
			<Box sx={{ ...styles.content, ...(rightImage ? styles.contentR : {}) }}>
				<Box sx={styles.imageContainer}>
					<img src={image} style={{ width: '100%' }} alt='Hmm' />
				</Box>
				<Box sx={styles.textContainer}>
					<Typography sx={styles.title}>{title}</Typography>
					<Button color='btn' variant='contained' onClick={onClick}>{t('nextButton')}</Button>
				</Box>
			</Box>
			<Box sx={{ ...styles.triangle, ...(rightImage ? styles.triangleR : {}) }} />
		</Box>
	);
};

/* eslint-disable react/forbid-prop-types */
Banner.propTypes = {
	title: PropTypes.string.isRequired,
	color: PropTypes.string,
	image: PropTypes.any.isRequired,
	rightImage: PropTypes.bool,
	onClick: PropTypes.any.isRequired,
};

Banner.defaultProps = {
	rightImage: false,
	color: '#FFF',
};

export default Banner;
