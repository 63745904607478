import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import prod1 from '../../assets/images/carousel/prod1.jpg';
import prod2 from '../../assets/images/carousel/prod2.jpg';
import Header from '../../components/Header';
import forward from '../../assets/images/forward.svg';
import Guarantee from '../../components/Guarantee';
import SliderDots from '../../components/SliderDots';

const IMAGES = [prod1, prod2];

const BP1 = '@media (max-width: 1279px)';
const BP2 = '@media (max-width: 779px)';
const BP3 = '@media (max-width: 569px)';
const BP4 = '@media (max-width: 289px)';

const styles = {
	root: {
		overflow: 'hidden',
		pt: '155px',
		boxSizing: 'border-box',
		transition: 'all .3s',
		[BP2]: {
			pt: '125px',
		},
	},
	carouselImageContainer: {
		width: '100%',
		outline: 'none',
		height: '100%',
	},
	triangle: {
		position: 'absolute',
		backgroundColor: 'background.primary',
		width: '100%',
		height: '100%',
		top: 0,
		clipPath: 'polygon(93% 0, 100% 0, 100% 100%, 70% 100%)',
	},
	content: {
		// position: 'relative',
		width: '100%',
		backgroundColor: '#F0F3F5',
		boxSizing: 'border-box',
		pb: '77px',
		pl: '16px',
		pr: '16px',
	},
	cardContainer: {
		width: '100%',
		maxWidth: '1128px',
		position: 'relative',
		mt: '-190px',
		ml: 'auto',
		mr: 'auto',
		transition: 'all .3s',
		[BP1]: {
			mt: '-130px',
		},
		[BP3]: {
			mt: '-100px',
		},
	},
	navBtnContainer: {
		width: '100%',
		mb: '20px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		[BP4]: {
			justifyContent: 'center',
		},
	},
	navBtn: {
		width: '40px',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		backgroundColor: '#FCFCFC',
		cursor: 'pointer',
		[BP4]: {
			display: 'none',
		},
	},
	card: {
		width: '100%',
		backgroundColor: '#FFF',
		borderRadius: '12px',
		boxShadow: '0px 50px 80px #0D988C1A',
		boxSizing: 'border-box',
		pt: '100px',
		pl: '20px',
		pr: '20px',
		pb: '97px',
		ml: 'auto',
		mr: 'auto',
	},
	titleContainer: {
		width: '100%',
		maxWidth: '936px',
		ml: 'auto',
		mr: 'auto',
	},
	title: {
		width: '100%',
		maxWidth: '936px',
		ml: 'auto',
		mr: 'auto',
		fontFamily: 'mw-bold',
		fontSize: '50px',
		lineHeight: '60px',
		letterSpacing: '-1px',
		color: 'text.primary',
		wordBreak: 'break-word',
		overflowWrap: 'anywhere',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		transition: 'all .3s',
		[BP3]: {
			fontSize: '40px',
			lineHeight: '48px',
		},
		[BP4]: {
			fontSize: '30px',
			lineHeight: '36px',
		},
	},
	text: {
		width: '100%',
		maxWidth: '936px',
		ml: 'auto',
		mr: 'auto',
		fontFamily: 'mw',
		fontSize: '24px',
		lineHeight: '40px',
		color: 'text.dark',
		wordBreak: 'break-word',
		overflowWrap: 'anywhere',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		transition: 'all .3s',
		[BP3]: {
			fontSize: '20px',
			lineHeight: '33px',
		},
		[BP4]: {
			fontSize: '18px',
			lineHeight: '30px',
		},
	},
	dot: {
		width: '12px',
		height: '12px',
		backgroundColor: '#002846',
		borderRadius: '50%',
		ml: 'auto',
		mr: 'auto',
		mt: '50px',
	},
};

const sliderConfig = (setCurrentSlide) => ({
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	vertical: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 5000,
	adaptiveHeight: true,
	afterChange: setCurrentSlide,
});

const Product = () => {
	const sliderRef = useRef(null);
	const [currentSlide, setCurrentSlide] = useState(0);
	const { t } = useTranslation(['products']);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const renderNavButton = (isNext, onClick) => (
		<Box sx={styles.navBtn} onClick={onClick}>
			<img src={forward} style={{ width: 24, transform: isNext ? 'none' : 'rotate(180deg)' }} alt={isNext ? 'Next' : 'Previous'} />
		</Box>
	);

	const renderText = () => (
		<>
			{t('text').split('\n').map((item, i) => (
				<Typography key={i.toString()} sx={styles.text}>{item}</Typography>
			))}
		</>
	);

	return (
		<>
			<Header shrunk onChange={() => {}} />
			<Box sx={styles.root}>
				<Box sx={{ position: 'relative', maxHeight: '830px', overflow: 'hidden' }}>
					<Slider {...(sliderConfig(setCurrentSlide))} ref={sliderRef}>
						{IMAGES.map((image, i) => (
							<Box key={i.toString()} sx={styles.carouselImageContainer}>
								<img src={image} style={{ width: '100%', minWidth: '480px' }} alt={`Carousel ${i + 1}`} />
							</Box>
						))}
					</Slider>
					<Box sx={styles.triangle} />
				</Box>
				<Box sx={styles.content}>
					<Box sx={styles.cardContainer}>
						<Box sx={styles.navBtnContainer}>
							{renderNavButton(false, () => sliderRef.current.slickPrev())}
							<SliderDots currentSlide={currentSlide} totalCount={IMAGES.length} />
							{renderNavButton(true, () => sliderRef.current.slickNext())}
						</Box>
						<Box sx={styles.card}>
							<Typography sx={{ ...styles.title, marginBottom: '10px' }}>__</Typography>
							<Box sx={styles.titleContainer}>
								<Typography sx={{
									...styles.title, maxWidth: '264px', ml: 'unset', mb: '40px',
								}}
								>
									{t('title')}
								</Typography>
							</Box>
							{renderText()}
							<Box sx={styles.dot} />
						</Box>
					</Box>
				</Box>
				<Guarantee />
			</Box>
		</>
	);
};

export default Product;
